<template>
    <div id="email-form">
        <div id="edit-btn-box" v-show="ulog">
            <v-btn id="edit-email-form-btn"
                color="error"
                @click="emailFormEditOverlay = !emailFormEditOverlay">
                    Edit form
            </v-btn>
        </div>
        <h1 class="form-title">{{formData.formTitle}}</h1>
        <p v-html="subtitleToHtml"></p>
        <h4>Fill out your details below:</h4>
        <div id="forms-container" @click="clearMsgs()">
            <v-form id="campaign-email-form" v-model="campaignFormValid">
                <v-text-field
                    v-model="email"
                    :rules="[emailRules.required, emailRules.valid, emailRules.max]"
                    outlined
                    color="primary"
                    background-color="#ffffff"
                    required
                    >
                    <template v-slot:label>
                        Email<span class="red--text"> *</span>
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="firstName"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    outlined
                    color="primary"
                    background-color="#ffffff"
                    required
                    >
                    <template v-slot:label>
                        First name<span class="red--text"> *</span>
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="lastName"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    outlined
                    color="primary"
                    background-color="#ffffff"
                    required
                    >
                    <template v-slot:label>
                        Last name<span class="red--text"> *</span>
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="city"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    outlined
                    color="primary"
                    background-color="#ffffff"
                    required
                    >
                    <template v-slot:label>
                        City/Town<span class="red--text"> *</span>
                    </template>
                </v-text-field>
            </v-form>
            <v-form id="rep-lookup" v-model="repFormValid" v-if="formData.sendType != 'closed'">
                <h2>Find your local representative:</h2>
                <v-text-field
                    v-model="street"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    outlined
                    color="primary"
                    background-color="#ffffff"
                    required
                    >
                    <template v-slot:label>
                        Street name and number (ex: 123 main street)<span class="red--text"> *</span>
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="cityLookup"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    outlined
                    color="primary"
                    background-color="#ffffff"
                    required
                    >
                    <template v-slot:label>
                        City/Town<span class="red--text"> *</span>
                    </template>
                </v-text-field>
                <v-text-field
                    id="postal-code-input" 
                    v-model="postalCode"
                    :rules="[postalCodeRules.required, postalCodeRules.min, postalCodeRules.max]"
                    outlined
                    color="primary"
                    background-color="#ffffff"
                    required
                    >
                    <template v-slot:label>
                        Postal Code<span class="red--text"> *</span>
                    </template>
                </v-text-field>
                <v-btn id="get-rep"
                    :disabled="!repFormValid"
                    elevation="6"
                    color="primary" 
                    @click="getRepresentative">
                        Search
                </v-btn>
                <div id="error-rep-msg">
                    <h2>{{errorRep}}</h2>
                </div>
                <div id="success-rep-msg">
                    <h2>{{successRep}}</h2>
                </div>
            </v-form>
        </div>
        <p v-if="formData.sendType == 'closed'"><span style="color:red"><b>Important!</b></span> This form must be fully filled out before sending email.</p>
        <p v-if="formData.sendType != 'closed'"><span style="color:red"><b>Important!</b></span> These forms must be fully filled out before sending email.</p>
        <div id="email-content">
            <h1>The email template:</h1>
            <h2>To:</h2>
            <p class="email-content-data">{{emailRecepients}}</p>
            <h2>Subject:</h2>
            <p class="email-content-data">{{formData.subject}}</p>
            <h2>Content:</h2>
            <p class="email-content-data" v-html="displayFormContent + '[Your Name Here]'"></p>
        </div>
        <hr>
        <p class="disclaimer">The send button will open your email app with all information prefilled.</p>
        <p class="disclaimer">We do this to ensure your voices are heard and emails dont get caught in spam folders. Your email is sent directly from you!</p>
        <p class="disclaimer">If you are using chrome and your email does not open. See this quick fix, <a class="anchor-color" target="_blank" href="https://www.timeatlas.com/email-links-chrome-gmail/">Solution 1</a>, to ensure your prefilled email opens.</p>
        <v-btn class="send-form-btn"
            :disabled="!campaignFormValid || this.emailRecepients == ''"
            elevation="12"
            color="primary"
            target="_blank" 
            :href="mailToString"
            @click="updateCampaignList">
                Send Email!
        </v-btn>
        <p class="disclaimer-privacy">
            We save your information to keep you informed about this campaign and future campaigns/alerts. Your data will never be shared or sold.<br>
            You can have your information removed from our system at anytime by contacting us <a class="anchor-color" href="/explore#contact-us-container">here</a>.
        </p>
        <v-overlay
            id="edit-email-form-overlay"
            v-show="ulog"
            :z-index="zIndex"
            :value="emailFormEditOverlay"
            :opacity="opacity"
            :absolute="absolute"
            >
            <div id="edit-email-form">
                <EditActionEmailForm :formData="formData" @changeOverlay="emailFormEditOverlay = !emailFormEditOverlay" />
            </div>
        </v-overlay>
    </div>
</template>

<script>
import axios from 'axios'
import { marked } from 'marked'
import EditActionEmailForm from '../components/EditActionEmailForm.vue'

    export default {
        name: "ActionEmailForm",
        components: {
            EditActionEmailForm
        },
        props: ["campaignId", "ulog"],
        computed: {
            mailToString() {
                return "mailto:"+this.formData.emailList+"?subject="+this.formData.subject+"&body="+(this.emailContent+(this.firstName+" "+this.lastName));
            },
            emailRecepients() {
                return this.formData.emailList; //using computed as the email list changes based on users location
            },
            subtitleToHtml() {
                if (this.formData.subtitle != null) {
                    return marked(this.formData.subtitle); // marked is package to parse user input to markdown/html. 
                }
                else {
                    return null;
                }
            },
        },
        mounted() {
            this.getFormData();
        },
        data() {
            return {
                formData: {},
                email: '',
                emailRules: {
                    required: v => !!v || 'E-mail is required',
                    valid: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    max: v => v.length <= 50 || 'Max 50 characters',
                },
                firstName: '',
                lastName: '',
                city: '',
                nameRules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 1 || 'Min 1 character',
                    max: v => v.length <= 50 || 'Max 50 characters',
                },
                postalCodeRules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 6 || 'Min 6 characters',
                    max: v => v.length <= 7 || 'Max 7 characters',
                },
                campaignFormValid: false,
                street: "",
                cityLookup: "",
                postalCode: "",
                errorRep: "",
                successRep: "",
                repFormValid: false,
                emailFormEditOverlay: false,
                opacity: 1,
                zIndex: 98,
                absolute: true,
                displayFormContent: null,
                emailContent: "",
            }
        },
        methods: {
            getFormData() {
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/action_form',
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        'campaignId': this.campaignId,
                    }
                }).then((response) => {
                    this.formData = response.data[0];
                    this.setFormContent(this.formData);
                }).catch(() => {
                    this.$emit("noForm") //sends parent(campaign page) event to hide the non-existent form and show the create form button instead
                });
            },
            setFormContent(form) {
                if (form.letterContent != null) {
                    this.displayFormContent = marked(form.letterContent); // marked is package to parse user input to markdown/html.
                    this.displayFormContent = this.displayFormContent.replace(new RegExp("&#39;", "g"), "'");
                    this.displayFormContent = this.displayFormContent.replace(new RegExp("&quot;", "g"), '"');
                    this.displayFormContent = this.displayFormContent.replace(new RegExp("&amp;", "g"), "and");
                    this.displayFormContent = this.displayFormContent.replace(/%/g, " percent");

                    try {
                        let new_format = this.displayFormContent.replace(new RegExp("<br>", "g"), "%0D%0A"); // replace line break tags with single line break
                        new_format = new_format.replace(new RegExp("</p>", "g"), "%0D%0A%0D%0A"); // replace closing p tag with double line break
                        new_format = new_format.replace( /(<([^>]+)>)/ig, ''); // replace all html tags with nothing.
                        this.emailContent = new_format;
                    } 
                    catch {
                        return null;
                    }
                }
                else {
                    this.displayFormContent = null;
                }
            },

            updateCampaignList() {
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/email_lists',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'tableType': 'campaign',
                        'email': this.email,
                        'firstName': this.firstName,
                        'lastName': this.lastName,
                        'city': this.city,
                    }
                }).then(() => {
                    // do nothing
                }).catch(() => {
                    //do nothing
                });
            },
            getRepresentative() {
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/representative',
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        'sendType': this.formData.sendType,
                        'street': this.street,
                        'city': this.cityLookup,
                        'postalCode': this.postalCode,
                    }
                }).then((response) => {
                    if (this.formData.emailList == null) {
                        this.formData.emailList = response.data.email;
                    }
                    else {
                        //check email is not being added again
                        if (!this.formData.emailList.includes(response.data.email)) {
                            if(this.formData.emailList.length == 0) {
                                this.formData.emailList += response.data.email; //append email if current emails exist in block
                            } else {
                                this.formData.emailList += ", "+response.data.email; //append email if current emails exist in block
                            }
                        }
                    }

                    if (this.formData.sendType == "mla") {
                        this.successRep = "Your MLA is "+response.data.name+". They've been added to the email template.";
                    } else if (this.formData.sendType == "councillor") {
                        if (response.data.name == "Jyoti Gondek") {
                            this.successRep = "Your representative is "+response.data.name+". They've been added to the email template.";
                        }
                        else {
                            this.successRep = "Your councillor is "+response.data.name+". They've been added to the email template.";
                        }
                    }
                }).catch((error) => {
                    this.errorRep = error.response.data;
                });
            },
            clearMsgs() {
                if (this.successRep != "") {
                    this.successRep = "";
                }
                if (this.errorRep != "") {
                    this.errorRep = "";
                }
            },
        }
    }
</script>

<style lang="scss" scoped>

    .anchor-color {
        color:#d57f1e;
    }

    #email-form {
        width: 95%;
        justify-self: center;
        background-color: #f8f8f8;
        border-radius: 5px;
        padding: 20px 20px;
        margin-bottom: 20px;

        #edit-btn-box {
            width: 100%;
            display: grid;
            justify-items: end;
            margin-bottom: 2vh;
        }

        .email-content-data {
            margin-left: 2vw;
        }

        .form-title {
            text-align: center;
        }

        h1, h2, h3, h4 {
            font-family: "Helvetica";
            color: #d57f1e;
        }

        h1 {
            margin-bottom: 30px
        }

        .send-form-btn {
            width: 100%;
            min-height: 40px;
            height: 8vh;
        }

        #rep-lookup {
            background-color: #aaaaaa42;
            padding: 0 20px;
            border-radius: 20px;
            margin-bottom: 2vh;

            h2 {
                margin: 10px 0;
            }
            
            #error-rep-msg > h2 {
                color: red;
            }

            #success-rep-msg > h2 {
                color: green;
            }

            #get-rep {
                margin-bottom: 1vh;
            }
        }

        hr {
            margin: 15px 30px;
        }

        .disclaimer {
            font-size: 0.7em;
            margin: 0 0 5px 0;
            text-align: start;
            color: red;
        }

        .disclaimer-privacy {
            font-size: 0.6em;
            margin: 4vh 0 5px 0;
            text-align: center;
        }

        #edit-email-form-overlay {
            display: grid;
            min-height: 100vh;
            height: fit-content;

            #edit-email-form {
                width: 90vw;
                margin-bottom: 4vh;
            }
        }
    }

@media screen and (min-width: 600px) {
    #email-form {
        width: 90%;
        display: grid;

        .send-form-btn {
            width: 90%;
            justify-self: center;
            min-height: 40px;
            height: 7vh;
            font-size: 1.5em;
        }

        .disclaimer {
            font-size: 0.7em;
            margin: 0 0 5px 0;
            justify-self: center;
        }

        .disclaimer-privacy {
            font-size: 0.6em;
            margin: 4vh 0 5px 0;
        }
    }
}

@media screen and (min-width: 1000px) {
    #email-form {
        width: 80%;
        justify-self: center;

        #forms-container {
            display: grid;
            grid-template-columns: 48% 48%;
            gap: 20px;

            #rep-lookup {
                position: relative;
                bottom: 25px;
            }
        }

        .send-form-btn {
            width: 80%;
            min-height: 40px;
            height: 6vh;
        }

        .disclaimer {
            font-size: 0.9em;
            margin: 0 0 5px 0;
        }

        .disclaimer-privacy {
            font-size: 0.8em;
            margin: 4vh 0 5px 0;
        }
    }
}
</style>