<template>
    <div id="edit-action-email">
        <h1>Edit this email form</h1>
        <v-form id="edit-action-email-form" v-model="formValid">
            <h3 id="statusTitle">Form Type:<span class="red--text"> *</span></h3>
            <v-radio-group 
                v-model="sendType"
                :rules="[sendTypeRules.required]"
                row>
                <v-radio
                    label="Closed"
                    value="closed"
                    color="#24a0ed"
                    >
                </v-radio>
                <v-radio
                    label="MLA"
                    value="mla"
                    color="#24a0ed"
                    >
                </v-radio>
                <v-radio
                    label="Councillor"
                    value="councillor"
                    color="#24a0ed"
                    >
                </v-radio>
            </v-radio-group>
            <v-text-field
                v-model="title"
                :rules="[titleRules.required, titleRules.min, titleRules.max]"
                label="Title"
                color="primary"
                required
                >
                <template v-slot:label>
                    Title<span class="red--text"> *</span>
                </template>
            </v-text-field>
            <v-textarea
                v-model="subtitle"
                :rules="[subtitleRules.max]"
                label="Subtitle"
                color="primary"
                auto-grow
            ></v-textarea>
            <h3>Email information: (Recipients, Subject line, email content)</h3>
            <v-text-field
                v-model="recipientList"
                :rules="[recipientListRules.max]"
                label="Email address(s)"
                color="primary"
            ></v-text-field>
            <v-text-field
                v-model="subject"
                :rules="[titleRules.max]"
                label="Email subject line"
                color="primary"
            ></v-text-field>
            <v-textarea
                v-model="content"
                :rules="[subtitleRules.max]"
                label="Email body"
                color="primary"
                auto-grow
            ></v-textarea>
            <div id="error-message">
                <h2>{{errorMsg}}</h2>
            </div>
            <div id="form-btns">
                <v-btn id="update-btn"
                    :disabled="!formValid"
                    color="success" 
                    @click="updateForm()">
                        Save
                </v-btn>
                <v-btn
                    id="delete-btn"
                    class="white--text"
                    color="error"
                    @click="hideShowBtns()"
                >
                    Delete Form
                </v-btn>
                <v-btn
                    id="sure-btn"
                    class="white--text"
                    color="#FF0000"
                    @click="deleteForm()"
                >
                    Click again to confirm delete 
                </v-btn>
                <v-btn
                    id="overlay-close-btn"
                    class="white--text"
                    color="error"
                    @click="updateParentPageFlow()"
                >
                    Close
                </v-btn>
            </div>
        </v-form>
        <MarkdownHtmlExamples />
    </div>
</template>

<script>
import axios from 'axios'
import router from '../router'
import cookies from 'vue-cookies'
import MarkdownHtmlExamples from '../components/MarkdownHtmlExamples.vue'

    export default {
        name: "EditActionEmailForm",
        props: ["formData"],
        components: {
            MarkdownHtmlExamples
        },
        data() {
            return {
                formValid: false,
                errorMsg: '',
                sendType: this.formData.sendType,
                sendTypeRules: {
                    required: v => !!v || 'Must Select an Option',
                },
                title: this.formData.formTitle,
                titleRules: {
                    required: v => !!v || 'Title is required',
                    min: v => v.length >= 1 || 'Min 1 character',
                    max: v => v.length <= 200 || 'Max 200 characters',
                },
                subtitle: this.formData.subtitle,
                subtitleRules: {
                    max: v => v.length <= 65000 || 'Max 65,000 characters',
                },
                recipientList: this.formData.emailList,
                recipientListRules: {
                    max: v => v.length <= 1000 || 'Max 1000 characters',
                },
                subject: this.formData.subject,
                content: this.formData.letterContent,
            }
        },
        methods: {
            updateParentPageFlow() {
                this.$emit('updateParentPageFlow');
                this.$emit("changeOverlay");
            },
            updateForm() {
                let token = cookies.get('_casLt')

                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/action_form',
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        "loginToken": token,
                        "formId": this.formData.formId,
                        "sendType": this.sendType,
                        "title": this.title,
                        "subtitle": this.subtitle,
                        "emailList": this.recipientList,
                        "subject": this.subject,
                        "content": this.content,
                    }
                }).then(() => {
                    router.go();
                }).catch((error) => {
                    this.errorMsg = error.response.data;
                })
            },
            deleteForm() {
                let token = cookies.get('_casLt')
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/action_form',
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        "loginToken": token,
                        "formId": this.formData.formId,
                    }
                }).then(() => {
                    router.go();
                }).catch((error) => {
                    this.errorMsg = error.response.data;
                })
            },
            hideShowBtns() {
                document.getElementById("delete-btn").style.display = "none";
                document.getElementById("sure-btn").style.display = "block";
            },
        }
    }
</script>

<style lang="scss" scoped>

    #edit-action-email {
        width: 100%;
        margin-top: 70px;
        display: grid;
        justify-items: center;
        align-items: center;

        h1 {
            text-align: center;
            font-family: "Helvetica";
        }

        #edit-action-email-form {
            width: 90vw;
            font-family: "Helvetica";

            #error-message {
                display: none;
                color: rgb(212, 0, 0);
                margin: 3vh 0;
            }

            #form-btns {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                #sure-btn {
                    display: none;
                }
            }

            #form-btns > * {
                margin-top: 10px;
            }
        }

        #markdown-code {
            width: 90vw;
            margin-top: 5vh;
            border: 3px solid white;
            border-radius: 30px;
            color: white;
            
            h1 {
                margin: 0;
                justify-self: center;
                text-decoration: underline;
            }

            h3 {
                margin-top: 3vh;
                margin-left: 1vw;
            }

            #markdown-list-item > * {
                color: white;
            }

            #markdown-list-item > #list-item-div > h4 {
                margin-top: 3vh;
                color: #e89f4c;
            }
        }
    }

@media screen and (min-width: 1000px) {
    #edit-action-email {
        margin-top: 90px;
        
        h1 {
            justify-self: start;
            margin-left: 15vw;
        }

        #edit-action-email-form {
            width: 60vw;
        }

        #markdown-code {
            width: 60vw;
        }
    }
}
</style>