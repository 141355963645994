<template>
    <div id="campaign-page">
        <div id="campaign-page-content" v-show="pageLoaded">
            <img id="campaign-img" @load="onImgLoad" :src="campaign.image_url" alt="Campaign Image">
            <div id="admin-btns" v-if="ulog">
                <v-btn
                    id="edit-btn"
                    v-if="ulog"
                    color="error"
                    @click="overlay = !overlay"
                    >
                    Edit
                </v-btn>
            </div>
            <h1 id="title">{{campaign.title}}</h1>
            <h3 id="subtitle" v-html="subtitleToHtml"></h3>
            <div id="content" v-html="contentToHtml"></div>
            <v-lazy
                v-model="lazyLinksCampForm"
                :options="{
                threshold: 1
                }"
                min-height="200"
                height="fit-content"
                transition="fade-transition"
                >
                <div id="campaign-form">
                    <h1 id="form-title" v-if="isEmailFormExist">Have your voice heard!</h1>
                    <v-btn
                        id="create-email-form-btn"
                        v-if="ulog && !isEmailFormExist"
                        color="primary"
                        @click="emailFormCreateOverlay = !emailFormCreateOverlay"
                        >
                        Create email form
                    </v-btn>
                    <div id="action-email-form">
                        <ActionEmailForm 
                            v-if="campaign.campaignId && isEmailFormExist" 
                            :campaignId="campaign.campaignId" 
                            :ulog="ulog" 
                            @noForm="isEmailFormExist = false"/>
                    </div>
                </div>
            </v-lazy>
            <div id="next-prev-btns-container">
                <v-btn 
                    id="prev-btn" class="next-prev-btns" color="primary" @click="prevCampaign()">
                    <v-icon>mdi-arrow-left</v-icon> Previous Campaign
                </v-btn>
                <v-btn id="next-btn" class="next-prev-btns" color="primary" @click="nextCampaign()">
                    Next Campaign <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
            </div>
        </div>
        <v-overlay
            id="edit-campaign-overlay"
            v-if="ulog"
            :z-index="zIndex"
            :value="overlay"
            :opacity="opacity"
            :absolute="absolute"
            >
            <div id="edit-campaign-form">
                <EditActionForm :campaign="campaign" @changeOverlay="overlay = !overlay"/>
            </div>
        </v-overlay>
        <v-overlay
            id="create-email-form-overlay"
            v-if="ulog"
            :z-index="zIndex"
            :value="emailFormCreateOverlay"
            :opacity="opacity"
            :absolute="absolute"
            >
            <div id="create-email-form">
                <CreateActionEmailForm :campaign="campaign" @changeOverlay="emailFormCreateOverlay = !emailFormCreateOverlay" />
            </div>
        </v-overlay>
    </div>
</template>

<script>
import axios from 'axios'
import router from '../router'
import cookies from 'vue-cookies'
import { marked } from 'marked'
import EditActionForm from '../components/EditActionForm.vue'
import ActionEmailForm from '../components/ActionEmailForm.vue'
import CreateActionEmailForm from '../components/CreateActionEmailForm.vue'

    export default {
        name: "Campaign",
        components: {
            EditActionForm,
            ActionEmailForm,
            CreateActionEmailForm,
        },
        computed: {
            subtitleToHtml() {
                if (this.campaign.subtitle != null) {
                    return marked(this.campaign.subtitle);
                }
                else {
                    return null;
                }
            },
            contentToHtml() {
                if (this.campaign.content != null) {
                    return marked(this.campaign.content);
                }
                else {
                    return null;
                }
            },
            sessionCookie() {
                return cookies.get('_casLt'); 
            },
            titleArray() {
                return this.$store.state.campaignTitleArray;
            }
        },
        beforeMount() {
            this.getCampaign();
            
            if (this.$store.state.campaignTitleArray == null) {
                this.getTitleArray();
            }
        },
        mounted() {
            if (this.sessionCookie != undefined){
                this.ulog = true;
            }
        },
        data() {
            return {
                pageLoaded: false,
                ulog: false,
                campaign: {},
                pageOverlayFlow: false,
                overlay: false,
                zIndex: 98,
                opacity: 1,
                absolute: true,
                emailFormCreateOverlay: false,
                isEmailFormExist: true,
                lazyLinksCampForm: false,
            }
        },
        methods: {
            getCampaign() {
                let title = this.$route.params.title;
                title = title.replace(/-/g, " " )

                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/action',
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        'title': title,
                    }
                }).then((response) => {
                    this.campaign = response.data[0];
                }).catch(() => {
                    router.push('/action');
                });
            },
            getTitleArray() {
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/action',
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    let allCampaigns = response.data.reverse(); //reverse so newest campaings show first
                    
                    //store campaign ids to be able to navigate through id pages
                    let titleArray = []
                    for (let i=0; i<allCampaigns.length; i++) {
                        let theTitle = allCampaigns[i].title;
                        theTitle = theTitle.replace(/ /g, "-" );
                        theTitle = theTitle.toLowerCase();
                        titleArray.push(theTitle);
                    }
                    this.$store.commit("titleArray", titleArray);
                }).catch((error) => {
                    console.log(error);
                })
            },
            nextCampaign() {
                let nextItem;

                //get next item index in campaign id array
                for (let i=0; i<this.titleArray.length; i++) {
                    if (this.titleArray[i] == this.$route.params.title) {
                        nextItem = this.titleArray.indexOf(this.titleArray[i]) + 1;
                    }
                }
                
                //push to that index value, if at end of array, push to first item
                if (nextItem < this.titleArray.length) {
                    router.push('/campaign/'+this.titleArray[nextItem]);
                } else {
                    router.push('/campaign/'+this.titleArray[0]);
                }
            },
            prevCampaign() {
                let prevItem;

                //get next item index in campaign id array
                for (let i=0; i<this.titleArray.length; i++) {
                    if (this.titleArray[i] == this.$route.params.title) {
                        prevItem = this.titleArray.indexOf(this.titleArray[i]) - 1;
                    }
                }
                
                //push to that index value, it at start of array, push to last item
                if (prevItem >= 0) {
                    router.push('/campaign/'+this.titleArray[prevItem]);
                } else {
                    router.push('/campaign/'+this.titleArray[this.titleArray.length -1]);
                }
            },
            onImgLoad() {
                this.pageLoaded = true;
            }
        }
    }
</script>

<style lang="scss" scoped>

#campaign-page {
        width: 100vw;
        background-color: white;
        min-height: 100vh;
        margin-top: 70px; //account for fixed nav-bar height
        margin-bottom: 70px; //account for absolute footer height
        box-shadow: 0 5px 15px  rgb(131, 131, 131);

        #edit-btn {
            margin-top: 1vh;
            width: 90px;
            position: relative;
            left: calc(95% - 90px)
        }

        #campaign-img {
            width: 100%;
            max-height: 30vh;
            object-fit: cover;
        }

        #title {
            color: #d57f1e;
            text-align: center;
            padding: 0 10px;
            font-family: "Helvetica";
            margin-bottom: 20px;
        }

        #subtitle {
            padding: 0 10px;
            margin-bottom: 40px;
        }

        #content {
            padding: 0 10px;
        }

        #campaign-form {
            margin-top: 100px;
            display: grid;
            
            #form-title {
                color: #d57f1e;
                text-align: center;
                padding: 0 10px;
                font-family: "Helvetica";
                font-size: 2.5em;
                margin-bottom: 40px;
                background:
                    linear-gradient(#5565d9 0 0) 50% 100% /40% 2px;
                background-repeat:no-repeat;
                padding-bottom:6px;
            }

            #create-email-form-btn {
                justify-self: center;
                margin-bottom: 30px;
            }

            #action-email-form {
                display: grid;
                width: 100%;
            }
        }

        #next-prev-btns-container {
            display: flex;
            justify-content: space-between;
            padding: 0 10px 20px 10px;
            .next-prev-btns {
                font-size: 0.6em;
                padding: 5px 5px;
            }
        }

        #edit-campaign-overlay, #create-email-form-overlay {
            display: grid;
            min-height: 100vh;
            height: fit-content;

            #edit-campaign-form, #create-email-form {
                width: 90vw;
                margin-bottom: 4vh;
            }
        }
    }

@media screen and (min-width: 600px) {
    #campaign-page {
        margin-bottom: 150px; //account for absolute footer height

        #campaign-img {
            max-height: 40vh;
        }

        #title {
            font-size: 3em;
            margin-top: 30px;
        }

        #subtitle {
            font-size: 1em;
            padding: 0 40px;
        }

        #content {
            padding: 0 40px;
        }
        #campaign-form {
            
            #form-title {
                font-size: 3em;
            }
        }

        #next-prev-btns-container {
            justify-content: space-around;
            padding: 50px 0;
            .next-prev-btns {
                font-size: 0.8em;
                padding: 10px 20px;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    #campaign-page {
        max-width: 1350px;
        margin-top: 80px; //account for fixed nav-bar height
        display: grid;

        #campaign-img {
            max-height: 50vh;
        }

        #title {
            margin-top: 50px;
        }

        #subtitle {
            padding: 0 70px;
        }

        #content {
            padding: 0 70px;
        }

        #next-prev-btns-container {
            .next-prev-btns {
                font-size: 1em;
            }
        }
    }
}
</style>