<template>
    <div id="markdown-code">
            <h1>Markdown/HTML examples</h1>
            <v-list-item id="markdown-list-item"
                    v-for="item in markdownItems"
                    :key="item.title"
                >
                <div id="list-item-div">
                    <h4>{{item.title}}</h4>
                    <p>{{item.code}}</p>
                    <p>{{item.example}}</p>
                    <p>{{item.example2}}</p>
                    <p>{{item.example3}}</p>
                </div>
            </v-list-item>
            <h3>Click <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">here</a> to learn about more basic syntax</h3>
        </div>
</template>

<script>
    export default {
        name: "MarkdownHtmlExamples",
        data() {
            return {
                markdownItems: [
                    {title: "Containers and justify", code: "<div style='width:100%;display:grid;justify-items:center;'> </div>", example: "If you want to center something on your page, add it inside this div tag"},
                    {title: "Headers(various sizes)", code: "<h1>This is a header</h1>", example: "You can use sizes 1 to 6. h1 is the biggest while h6 is the smallest", example2: "To change the color use the style tag Example:", example3: '<h1 style="color:red">Header</h1>'},
                    {title: "Paragraphs", code: "Adding two spaces in a row OR just hitting enter like a regular paragraph"},
                    {title: "Adding CAS color to text", code: 'style="color=#d57f1e"'},
                    {title: "Line breaks", code: "<br>", example:"- Example: line one here <br>", example2:"now we are on a new line"},
                    {title: "Wrapping links in email content form", code: "In your email content form, you must wrap all links with:", example: '<a href="javascript:void(0)">something@gmail.com</a>'},
                    {title: "Links", code: "[What you'd like the link to say](https://actualLinkHere.com)"},
                    {title: "Opening a link in a new tab", code: '<a href="http://example.com/" target="_blank">What the link should say here</a>'},
                    {title: "Photos", code: "<img style='max-width:99%;' src='https://photourlhere.com' alt='' />", example: "- To change size add width='50%' AND/OR height='50%' right after max-width=99%;", example2: "- DONT remove the max width setting, it might break the page due to large image sizes.", example3: "Adding both height AND width adjustments will warp the image, to maintain aspect ratio, only change one size and it will handle the rest."},
                    {title: "A photo Link", code: "<a href='http://example.com/' target='_blank'><img style='max-width:99%;' src='https://photourlhere.com' alt='' /></a>", example: "- Fill in the alt tag so blind people using screen readers will know what the image is"},
                    {title: "Embedding youtube videos to the page", code: "- Go to the youtube video of your choice. Click share. Click the Embed option.", example:"- Copy the entire code given and paste it where you want in your content or subtitle section.", example2: "- IMPORTANT! please add style='overflow:hidden;max-height:100%;max-width:100%' right after the opening tag to ensure the video fits mobile phones.", example3: "- You'll notice the size options for setting your video size are already in the code you copied"},
                    {title: "Style options for photos and videos", code: "- To float and image left for right, add style='float: right' or left after the opening tag", example: "- To move an image left, right, up, down add style='margin-top: 50%'", example2: "- margin-top moves it down, margin-bottom move it up, margin-left moves it right, margin-right moves it left."},
                    {title: "A note about setting HTML sizes", code: "Using px (pixels) will set the image size true to the pixel size you give it", example: "Setting the size using percentages will set the image true to the size of the window its in, so the image will change sizes depending on the screen size that is viewing it."},
                    {title: "Bold", code: "**word**", example: "Example: Love **is** bold."},
                    {title: "italics", code: "*word*", example: "Example: The cat *meows*"},
                    {title: "Bold and italic", code: "***word***", example: "Just ***another*** example"},
                    {title: "Block Quotes", code: ">", example: "> This will be put in a block quote"}
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
#markdown-code {
    width: 90vw;
    margin-top: 5vh;
    border: 3px solid white;
    border-radius: 30px;
    color: white;
    display: grid;
    
    h1 {
        margin: 0;
        justify-self: center;
        text-decoration: underline;
    }

    h3 {
        margin-top: 3vh;
        margin-left: 1vw;
    }

    #markdown-list-item > * {
        color: white;
    }

    #markdown-list-item > #list-item-div > h4 {
        margin-top: 3vh;
        color: #e89f4c;
    }
}

@media screen and (min-width: 1000px) {
    #markdown-code {
        width: 60vw;
    }
}
</style>