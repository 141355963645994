<template>
    <div id="create-action-email">
        <h1>Create an email form for this campaign.</h1>
        <v-form id="create-campaign-form" v-model="formValid">
            <h3 id="statusTitle">Form Type:<span class="red--text"> *</span></h3>
            <v-radio-group 
                v-model="sendType"
                :rules="[sendTypeRules.required]"
                row>
                <v-radio
                    label="Closed"
                    value="closed"
                    color="#24a0ed"
                    >
                </v-radio>
                <v-radio
                    label="MLA"
                    value="mla"
                    color="#24a0ed"
                    >
                </v-radio>
                <v-radio
                    label="Councillor"
                    value="councillor"
                    color="#24a0ed"
                    >
                </v-radio>
            </v-radio-group>
            <v-text-field
                v-model="title"
                :rules="[titleRules.required, titleRules.min, titleRules.max]"
                color="primary"
                required
                >
                <template v-slot:label>
                    Title<span class="red--text"> *</span>
                </template>
            </v-text-field>
            <v-textarea
                v-model="subtitle"
                :rules="[subtitleRules.max]"
                label="Subtitle"
                color="primary"
                auto-grow
            ></v-textarea>
            <h3>Email information: (Recipients, Subject line, email content)</h3>
            <v-text-field
                v-model="recipientList"
                :rules="[recipientListRules.max]"
                label="Email address(s)"
                color="primary"
            ></v-text-field>
            <v-text-field
                v-model="subject"
                :rules="[titleRules.max]"
                label="Email subject line"
                color="primary"
            ></v-text-field>
            <v-textarea
                v-model="content"
                :rules="[subtitleRules.max]"
                label="Email body"
                color="primary"
                auto-grow
            ></v-textarea>
            <div id="error-message">
                <h2>{{errorMsg}}</h2>
            </div>
            <div id="form-btns">
                <v-btn id="create-btn"
                    :disabled="!formValid"
                    color="success" 
                    @click="createForm()">
                        Create Form
                </v-btn>
                <v-btn
                    id="overlay-close-btn"
                    class="white--text"
                    color="primary"
                    @click="changeOverlay()"
                >
                    Close
                </v-btn>
            </div>
        </v-form>
        <MarkdownHtmlExamples />
    </div>
</template>

<script>
import axios from 'axios'
import cookies from 'vue-cookies'
import router from '../router'
import MarkdownHtmlExamples from '../components/MarkdownHtmlExamples.vue'

    export default {
        name: "CreateActionEmailForm",
        props: ["campaign"], //must be added in form POST api call
        components: {
            MarkdownHtmlExamples
        },
        data() {
            return {
                formValid: false,
                errorMsg: '',
                sendType: '',
                sendTypeRules: {
                    required: v => !!v || 'Must Select an Option',
                },
                title: '',
                titleRules: {
                    required: v => !!v || 'Title is required',
                    min: v => v.length >= 1 || 'Min 1 character',
                    max: v => v.length <= 200 || 'Max 200 characters',
                },
                subtitle: '',
                subtitleRules: {
                    max: v => v.length <= 65000 || 'Max 65,000 characters',
                },
                recipientList: '',
                recipientListRules: {
                    max: v => v.length <= 1000 || 'Max 1000 characters',
                },
                subject: '',
                content: '',
            }
        },
        methods: {
            changeOverlay() {
                this.$emit("changeOverlay")
            },
            createForm() {
                let token = cookies.get('_casLt')

                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/action_form',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        "loginToken": token,
                        "campaignId": this.campaign.campaignId,
                        "sendType": this.sendType,
                        "title": this.title,
                        "subtitle": this.subtitle,
                        "emailList": this.recipientList,
                        "subject": this.subject,
                        "content": this.content,
                    }
                }).then(() => {
                    router.go();
                }).catch((error) => {
                    this.errorMsg = error.response.data;
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    #create-action-email {
        width: 100%;
        margin-top: 30px;

        h1 {
            text-align: center;
            font-family: "Helvetica";
        }

        h3 {
            font-family: "Helvetica";
            margin-top: 50px;
            color: #e89f4c;
        }

        #create-campaign-form {
            width: 90vw;
            font-family: "Helvetica";

            #error-message {
                color: rgb(212, 0, 0);
                margin: 3vh 0;
            }

            #form-btns {
                display: flex;
                justify-content: space-between;
            }
        }

        #markdown-code {
            width: 90vw;
            margin-top: 5vh;
            border: 3px solid white;
            border-radius: 30px;
            color: white;
            
            h1 {
                margin: 0;
                justify-self: center;
                text-decoration: underline;
            }

            h3 {
                margin-top: 3vh;
                margin-left: 1vw;
            }

            #markdown-list-item > * {
                color: white;
            }

            #markdown-list-item > #list-item-div > h4 {
                margin-top: 3vh;
                color: #e89f4c;
            }
        }
    }

@media screen and (min-width: 1000px) {
    #create-action-email {
        display: grid;
        justify-items: center;
        
        h1 {
            justify-self: start;
            margin-left: 15vw;
        }

        #create-campaign-form {
            width: 60vw;
        }

        #markdown-code {
            width: 60vw;
        }
    }
}
</style>